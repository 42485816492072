import * as React from 'react';
import {graphql, useStaticQuery, PageProps} from 'gatsby';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobe} from '@fortawesome/free-solid-svg-icons';
import {faGithub, faTwitter, faLinkedin, faMediumM} from '@fortawesome/free-brands-svg-icons';

import Layout from '../layout';
import {ContainerLg} from '../components';
import styled from 'styled-components';
import {breakpointsMediaQueries} from '../../style/my-theme';

export type SpeakerProps = PageProps & {
  data: any;
  pageContext: {
    frontmatter: {[k: string]: string};
  };
};

export const pageQuery = graphql`
  query SpeakerPageQuery($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        fullName
        title
        company
        talkTitle
        talkDescription
        website
        github
        linkedin
        twitter
        imageSrc {
          childImageSharp {
            gatsbyImageData(width: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        previewSrc {
          id
          publicURL
        }
      }
    }
  }
`;

const StyledSpeakerPage = styled.div.attrs({
  className: 'speaker-container'
})`
  display: grid;
  grid-template-columns: 40% 60%;
  ${breakpointsMediaQueries.md},
  ${breakpointsMediaQueries.sm} {
    grid-template-columns: initial;
  }
  min-height: calc(100vh - 64px);
  padding: 4rem 0;

  .speaker-info {
    width: 340px;

    .speaker-title {
      display: grid;
      padding: 1rem;
      background: #100f21;
      color: #fff;
      text-align: center;

      .name {
        font-size: 1.5rem;
      }
    }
  }
  .speaker-description {
    ${breakpointsMediaQueries.md},
    ${breakpointsMediaQueries.sm} {
      margin-top: 2rem;
    }
    .speaker-links {
      display: flex;
      gap: 1rem;
      .link-box {
        background: #f6df19;
        color: #212121;
        width: 2rem;
        height: 2rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 100%;
        display: block;
      }
    }
  }
`;

const MarkdownTemplate: React.FC<SpeakerProps> = ({children, data}) => {
  const image = getImage(data.mdx.frontmatter.imageSrc);
  const title = `${data.mdx.frontmatter.fullName} - ${
    data.mdx.frontmatter.talkTitle ? data.mdx.frontmatter.talkTitle : ''
  } - Armada JS 2024`;

  return (
    <Layout
      title={title}
      description={data.mdx.frontmatter.talkDescription}
      imageUrl={`https://armada-js.com${data.mdx.frontmatter.previewSrc?.publicURL}`}
    >
      <ContainerLg>
        <StyledSpeakerPage>
          <div className="speaker-info">
            {image && <GatsbyImage image={image} alt={data.mdx.frontmatter.fullName} />}
            <div className="speaker-title">
              <span className="name">{data.mdx.frontmatter.fullName}</span>
              <span>
                {data.mdx.frontmatter.title}{' '}
                {data.mdx.frontmatter.company ? `@ ${data.mdx.frontmatter.company}` : ``}
              </span>
            </div>
          </div>

          <div className="speaker-description">
            <div className="speaker-links">
              {data.mdx.frontmatter.website && (
                <a href={data.mdx.frontmatter.website} className="link-box" target="_blank">
                  <FontAwesomeIcon icon={faGlobe} />
                </a>
              )}
              {data.mdx.frontmatter.github && (
                <a href={data.mdx.frontmatter.github} className="link-box" target="_blank">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              )}
              {data.mdx.frontmatter.linkedin && (
                <a href={data.mdx.frontmatter.linkedin} className="link-box" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              )}
              {data.mdx.frontmatter.twitter && (
                <a href={data.mdx.frontmatter.twitter} className="link-box" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              )}
              {data.mdx.frontmatter.medium && (
                <a href={data.mdx.frontmatter.medium} className="link-box" target="_blank">
                  <FontAwesomeIcon icon={faMediumM} />
                </a>
              )}
            </div>
            <div className="markdown-content">
              <MDXProvider components={{}}>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </StyledSpeakerPage>
      </ContainerLg>
    </Layout>
  );
};

export default MarkdownTemplate;
